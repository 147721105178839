import { z } from "zod";

export const schema = z.object({
  branch: z
    .string({ required_error: "Branch harus dipilih dari opsi yang tersedia." })
    .min(1, "Branch harus dipilih dari opsi yang tersedia."),
  email: z
    .string({ required_error: "Email harus diisi" })
    .email("Email harus diisi dan dalam format yang valid."),
  password: z
    .string({ required_error: "Kata sandi harus diisi" })
    .regex(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
      "Kata sandi minimal 8 karakter dengan kombinasi huruf, angka, dan karakter khusus"
    ),
});

export type LoginSchema = z.infer<typeof schema>;
